<template>
    <div class="Equip_box">
         <!-- 加载动画 -->
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
        <Header></Header>
        <div class="equip_cont">
            <div class="item_top">
                <span class="types_title">
                    海尔斯跑鞋
                </span>
                <div class="types_info">
                    <span class="type_line"></span>
                    <span class="info_text">HEALTH running shoes</span>
                    <span class="type_line"></span>
                </div>
            </div>
            <ul class="equip_lists clearfix">
                <template v-if="equiplists.length">
                    <li class="list3 f_left" v-for="(item,index) in equiplists" :key="index" @click="openDownload">
                        <img :src="item.goods_main_photo" :alt="item.goods_name" class="list3_img">
                        <div class="list3_info">
                            <span class="tit text_twoLine">{{item.goods_name}}</span>
                            <div class="score">
                                <el-rate
                                class="score_icon"
                                v-model="item.goods_point"
                                disabled
                                disabled-void-color="#D5D5D5"
                                >
                                </el-rate>
                                {{item.goods_point|dealscore}}分
                            </div>
                            <div class="price clearfix">
                                <span class="price_info f_left">￥
                                    <span class="price_num">{{item.goods_current_price|dealscore}}</span>
                                </span>
                                <span class="pinkage f_right">包邮</span>
                            </div>
                        </div>
                    </li>
                </template>
                <template v-else>
                    <li class="nolist">暂无装备内容</li>
                </template>
            </ul>
            <!-- <div class="pages" v-if="totalnum!=0">
                <el-pagination
                background
                @current-change="pageChange"
                layout="prev,pager,next"
                :total="totalnum">
                </el-pagination>
            </div>   -->
        </div>
        <Footer :footerBgColor="'#FBFBFB'"></Footer>
            <!-- 弹框下载app -->
        <Download
        :isDownloadshow="isDownloadshow"
        :url="downloadUrl"
        @closeAlert="closeAlert"></Download>
    </div>
</template>

<script>
import linkedmeLink from '../../config/linkdeme';
import {equip} from '../../config/request';
import axios from 'axios';
export default {
    components:{
    },
    filters: {
        strlen(str) {
            return str.length>11 ? str.substr(0,11)+'...' : str;
        },
        // 装备评分保留一位小数
        dealscore(v) {
            return Number(v).toFixed(1);
        }
    },
    data() {
        return {
            title:'海尔斯专业马拉松跑鞋82555545 ',
            totalnum:25,
            isDownloadshow:false,//是否显示
            downloadUrl:'',//二维码地址
            equiplists:[],//装备列表
            isLoading:true
        }
    },
    methods: {
        pageChange(page) {
            console.log(page)
        },
        //获取装备
        getEquip() {
            equip.lists({}).then(res => {
                this.isLoading = false;
                if(res.code =='true') {
                    this.equiplists = res.goods_list;
                    this.totalnum =  res.goods_list.length;
                }
            })
            // return new Promise((resolve, reject) => {
            //     axios({
            //         url: 'https://www.qoqq.com/app/goods_list.htm',
            //         method: 'get',
            //         data: {}
            //         }).then((res) => {
            //             if(res.code =='true') {
            //                 this.equiplists = res.goods_list;
            //                 this.totalnum =  res.goods_list.length;
            //                 resolve(res.data);
            //             }
            //         })
            // });
        },
        // 点击装备提示下载app
        openDownload() {
            this.isDownloadshow = true;
            // console.log( this.$route.query.id)
            // 进入装备
            linkedmeLink({
                path: 'run/equipment', //跳转app的名字
            }).then(res => {
                console.log(res)
                this.downloadUrl = res.url;
            })
        },
         // 关闭下载app弹框
        closeAlert() {
            this.isDownloadshow = false;
        },
    },
    mounted() {
        this.getEquip();
        // console.log($('#login').css('display','block'))
    }
}
</script>

<style>
@import './style/equip.css'
</style>
